<template>
  <div class="container" id="legalEntitiesOverview">
    <h1 :class="this.isActive ? '' : 'inactive-cell'">
      {{ this.dairy['title' + $route.params.upLang] }}
    </h1>

    <v-tabs v-model="activeTab" color="primary">
      <v-tab value="overview" v-html="$t('masterdata_farms_overview')"></v-tab>
      <v-tab value="contacts" v-html="$t('masterdata_dairies_contacts')"></v-tab>
      <v-tab value="relations" v-html="$t('masterdata_dairies_relations')" v-if="!readonly"></v-tab>
    </v-tabs>

    <v-window v-model="activeTab">
      <v-window-item value="overview"> <dairies-overview v-if="ready" :dairy="this.dairy" /></v-window-item>
      <v-window-item value="contacts"> <dairies-addresses /> </v-window-item>
      <v-window-item value="relations"> <dairies-relations :dairy-id="id" /> </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import dairiesOverview from '@/views/dairies/overview'
import dairiesRelations from '@/views/dairies/relations'
import DairiesAddresses from '@/views/dairies/addresses'
import { privileges } from '@/services/privileges'
import { showError } from '@/services/axios'
import { apiURL } from '@/main'

let today = new Date()
let yesterday = moment(today).subtract(1, 'day').toISOString()

import { defineComponent } from 'vue'
import moment from 'moment'
export default defineComponent({
  name: 'dairiesEdit',
  components: { DairiesAddresses, dairiesRelations, dairiesOverview },
  props: {
    id: { type: Number, required: true },
    tab: { type: String, required: true }
  },
  data() {
    return {
      apiURL: apiURL + '/dairies/',
      formPart: {
        id: null
      },
      dairy: {},
      ready: false,
      isActive: true
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/dairies', permission: 'write' })
    },
    activeTab: {
      get(): String {
        return this.tab
      },
      set(tabName: String) {
        this.$router
          .push({
            name: 'masterdata_dairies_edit',
            params: {
              id: this.id,
              tab: tabName
            }
          })
          .catch((e) => {
            /* duplicated route */
          })
      }
    }
  },
  methods: {
    async loadDairy(dairyId: Number) {
      try {
        let response = await this.axios.get((this.apiURL += dairyId), { headers: { 'Content-Type': 'application/json' } })

        this.dairy = response.data
        this.ready = true
        if (this.dairy.validUntil <= yesterday) {
          this.isActive = false
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  beforeMount() {
    this.loadDairy(this.id)
  }
})
</script>
